import React, { useCallback } from 'react'

interface SubtitleProps {
  children: string,
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p',
  className?: string
}

const Subtitle = React.forwardRef<HTMLHeadingElement, SubtitleProps>(({ tag, className, children }, ref) => {
  const Tag = tag

  const wrapWords = useCallback((text: string) => {
    return text.replace(
      // eslint-disable-next-line no-control-regex
      /([^\x00-\x80]|\S|\w,)+/g,
      `<span class="inline-block leading-relaxed overflow-hidden">
        <span class="inline-block leading-none" data-word>$&</span>
      </span>`
    )
  }, [])

  return (
    <Tag 
      ref={ref}
      className={`${className} text-18 leading-0 uppercase font-medium`}
      dangerouslySetInnerHTML={{ __html: wrapWords(children) }}
    />
  )
})

export default Subtitle