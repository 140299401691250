import { useEffect, useState } from 'react'
import gsap from 'gsap'

import Subtitle from './Subtitle'

import ArrowButton from '@components/icons/ArrowButton'
import Close from '@components/icons/Close'
import { TToaster } from '../../types'

interface ToasterProps {
  toaster: TToaster,
}

const Toaster = ({ toaster: { title, subtitle, href, close } }: ToasterProps) => {
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if (!toggle) return
    gsap.to('[data-toaster]', {
      duration: 0.5,
      autoAlpha: 0,
      display: 'none',
      y: 50,
    })
  }, [toggle])

  return (
    <div data-toaster className="hidden opacity-0 transform translate-y-50 fixed z-20 left-0 bottom-25 px-25 lg:bottom-80 lg:left-container lg:px-0">
      <div className="group bg-white text-black rounded shadow-xl transition-transform transform hover:-rotate-1 hover:scale-105">
        <div className="relative py-14 pl-14 pr-55 transition-transform transform group-hover:rotate-1">
          <div className="group/inner flex items-center gap-24" data-seo-container>
            <i className="block w-74 h-74 basis-74 text-primary transform -rotate-90 transition-transform group-hover/inner:rotate-0">
              <ArrowButton />
            </i>
            <div className="flex-1">
              <Subtitle tag="p">{title}</Subtitle>
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                className="text-18 font-standard leading-snug transition-colors group-hover/inner:text-primary"
                data-seo-target
                dangerouslySetInnerHTML={{ __html: subtitle }} />
            </div>
          </div>

          <button
            onClick={() => setToggle(!toggle)}
            className="absolute top-20 right-20 z-10 transition-transform hover:scale-110">
            <i className="block w-20 h-20 text-black">
              <Close />
            </i>
            <span className="sr-only">{close}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Toaster