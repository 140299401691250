import React, { useState, useEffect } from 'react'
import ExportedImage from 'next-image-export-optimizer'

import image1 from '@public/images/hero_01.png'
import image2 from '@public/images/hero_02.png'
import image3 from '@public/images/hero_03.png'
import image4 from '@public/images/hero_04.png'
import image5 from '@public/images/hero_05.png'

const images = [
  { src: image1, alt: 'Site web de la Connaissance des arts' },
  { src: image2, alt: 'Application Le Parisien' },
  { src: image3, alt: 'Site web de la Fondation Tara Océan' },
  { src: image4, alt: 'Application RTL' },
  { src: image5, alt: 'Site web d\'Eluxtravel' },
]

const ImagesGalleryFallback = () => {
  const [currentImage, setCurrentImage] = useState(images[0])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (currentIndex + 1) % images.length
      setCurrentImage(images[newIndex])
      setCurrentIndex(newIndex)
    }, 3000)
    return () => clearInterval(interval)
  }, [currentIndex])

  return (
    <ExportedImage
      src={currentImage.src}
      blurDataURL={currentImage.src.blurDataURL}
      placeholder="blur"
      alt={currentImage.alt}
      fill
    />
  )
}

export default ImagesGalleryFallback