import { useState } from 'react'

import { AppContext } from '@components/contexts/AppContext'

import useSessionStorage from '@hooks/useSessionStorage'
import useTranslation from '@hooks/useTranslation'
import Layout from '@components/layouts/Layout'
import Hero from '@components/sections/Hero'
import Keyfigures from '@components/sections/Keyfigures'
import Projects from '@components/sections/Projects'
import Brands from '@components/sections/Brands'
import Agency from '@components/sections/Agency'
import Skills from '@components/sections/Skills'
import Awards from '@components/sections/Awards'
import Founders from '@components/sections/Founders'
import WTTJ from '@components/sections/WTTJ'
import Team from '@components/sections/Team'
import Toaster from '@components/ui/Toaster'

import {
  Keyfigure,
  TProject,
  Theme,
  Brand,
  Image,
  CTA,
  Category,
  Award,
  TwoLinesSubtitle,
  TToaster,
} from '../types'

export default function Home() {
  const { t, o } = useTranslation()
  const [theme, setTheme] = useState<Theme>('primary')
  const [headerHeight, setHeaderHeight] = useState(0)
  const loader = useSessionStorage('loader')
  const [isMounted, setIsMounted] = useState(false)
  const [appVisible, setAppVisible] = useState(false)
  const [loaderVisible, setLoaderVisible] = useState(false)
  const origin = typeof window !== 'undefined' ? window.location.origin : ''

 return (
    <AppContext.Provider value={{
      theme,
      setTheme,
      headerHeight,
      setHeaderHeight,
      loader,
      isMounted,
      setIsMounted,
      appVisible,
      setAppVisible,
      loaderVisible,
      setLoaderVisible,
      origin
    }}>
      <Layout>
        <div data-section="primary" className="bg-primary text-white pb-50 lg:pb-100">
          <div data-section-scale>
            <Hero title={t('home.hero.title')} subtitle={t('home.hero.subtitle')} appIsReady={isMounted} />
          </div>
        </div>

        <div data-section="black" className="bg-black text-white rounded-t-15 pb-50 lg:pb-100">
          <div data-section-scale>
            <Keyfigures keyfigures={o('home.keyfigures') as Keyfigure[]} />
            <Projects
              title={t('home.projects.title')}
              projects={o('home.projects.items') as TProject[]}
            />
            <Brands brands={o('home.brands') as Brand[]} />
          </div>
        </div>

        <div data-section="white" className="bg-white bg-skills-gradient bg-left-bottom bg-no-repeat bg-full text-black rounded-t-15 pb-50 lg:pb-100">
          <div data-section-scale>
            <Agency
              image={o('home.agency.image') as Image}
              title={t('home.agency.title')}
              subtitle={t('home.agency.subtitle')}
              cta={o('home.agency.cta') as CTA}
            />
            <Skills
              title={t('home.skills.title')}
              categories={o('home.skills.categories') as Category[]}
            />
          </div>
        </div>

        <div data-section="black" className="bg-black text-white rounded-t-15 pb-50 min-h-pane lg:pb-100 lg:min-h-0">
          <div data-section-scale>
            <Awards
              title={t('home.awards.title')}
              awards={o('home.awards.items') as Award[]}
              max={4}
            />
          </div>
        </div>

        <div data-section="primary" className="bg-primary text-white rounded-t-15 pb-50 lg:pb-100">
          <div data-section-scale>
            <Founders
              title={t('home.founders.title')}
              subtitle={o('home.founders.subtitle') as TwoLinesSubtitle}
              image={o('home.founders.image') as Image}
            />
            <WTTJ
              title={t('home.wttj.title')}
              cta={o('home.wttj.cta') as CTA}
            />
            <Team
              title={t('home.team.title')}
              images={o('home.team.images') as Image[][]}
            />
          </div>
        </div>

        <Toaster toaster={o('home.awards.toaster') as TToaster} />
      </Layout>
    </AppContext.Provider>
  )
}