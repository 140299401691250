import React, { useRef } from 'react'

import ArrowHover from '@components/icons/ArrowHover'
import ImageFallback from '@components/ui/ImageFallback'

import { TProject } from '../../types'

const Project = ({ title, subtitle, color, video, image }: TProject) => {
  const mainEl = useRef<HTMLDivElement>(null)
  const iconHoverEl = useRef<HTMLElement>(null)
  const videoEl = useRef<HTMLVideoElement>(null)

  const style = {
    '--mouse-x': 0,
    '--mouse-y': 0,
  } as React.CSSProperties

  const updateMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!iconHoverEl.current) return
  
    const target = e.target as HTMLElement
    iconHoverEl.current.classList.remove('opacity-0', 'scale-50')
    const rect = target.getBoundingClientRect()
    const x = (e.clientX - rect.left - (iconHoverEl.current.clientWidth / 2)) * 100 / target.clientWidth
    const y = (e.clientY - rect.top - (iconHoverEl.current.clientHeight / 2)) * 100 / target.clientHeight
    iconHoverEl.current.style.setProperty('--mouse-x', `${x}%`)
    iconHoverEl.current.style.setProperty('--mouse-y', `${y}%`)
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    requestAnimationFrame(() => updateMouseMove(e))
  }

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (!iconHoverEl.current) return
      iconHoverEl.current.classList.add('opacity-0', 'scale-50')
    }, 100)
  }

  const handleFocus = () => {
    if (window.matchMedia('(hover: none)').matches) {
      videoEl.current?.play()
    }
  }

  const handleBlur = () => {
    if (window.matchMedia('(hover: none)').matches) {
      videoEl.current?.pause()
    }
  }

  return (
    <article
      ref={mainEl}
      className="group"
      onMouseEnter={() => videoEl.current?.play()}
      onMouseLeave={() => videoEl.current?.pause()}
      onFocus={handleFocus}
      onBlur={handleBlur}
      >
      <div
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        className={`relative p-20 bg-project-${color} flex items-center justify-center aspect-project hover:cursor-none`}>

        <ImageFallback
          className={`pointer-events-none ${color === 'gulli' ? 'translate-y-12' : ''}`}
          src={image.src}
          alt={title}
          width={image.width}
          height={image.height}
          fallbackSrc={image.fallback}
        />

        <div className="absolute z-10 inset-0 opacity-0 transition-opacity pointer-events-none group-hover:opacity-100">
          <video ref={videoEl} className="w-full h-full object-cover" src={video} loop muted playsInline />
        </div>
        <i
          ref={iconHoverEl}
          className="hidden hover-hover:block absolute z-20 top-[var(--mouse-y)] left-[var(--mouse-x)] w-100 h-100 opacity-0 transform scale-50 transition-cursor-project text-white pointer-events-none backdrop-blur rounded-full"
          style={style}>
          <ArrowHover />
        </i>
      </div>
      <p className="mt-15 text-18 text-white opacity-0 transform -translate-y-10 transition-all duration-500 group-hover:opacity-100 group-hover:translate-y-0 hover-none:opacity-100 hover-none:translate-y-0">
        {title} — <span className="text-gray-medium">{subtitle}</span>
      </p>
    </article>
  )
}

export default Project
