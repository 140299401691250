import React, { useState } from 'react'
import ExportedImage from 'next-image-export-optimizer'

interface ImageFallbackProps {
  src: string,
  fallbackSrc: string,
  className?: string,
  alt?: string,
  width?: number,
  height?: number,
  fill?: boolean,
}

const ImageFallback = (props: ImageFallbackProps) => {
  const { src, fallbackSrc, fill, ...rest } = props
  const [imgSrc, setImgSrc] = useState(src)

  return (
    // @ts-ignore
    <ExportedImage
      {...rest}
      src={imgSrc}
      fill={fill ? true : false}
      onError={() => {
        setImgSrc(fallbackSrc)
      }}
    />
  )
}

export default ImageFallback