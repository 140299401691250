import ExportedImage from 'next-image-export-optimizer'

import AgencyImage from '@public/images/agency.webp'

import Container from '@components/ui/Container'
import Title from '@components/ui/Title'
import Subtitle from '@components/ui/Subtitle'
import ButtonPrimary from '@components/ui/ButtonPrimary'
import ArrowButton from '@components/icons/ArrowButton'
import { Image, CTA } from '../../types'

interface AgencyProps {
  title: string
  subtitle: string
  image: Image,
  cta: CTA,
}

const Agency = ({ title, subtitle, image, cta }: AgencyProps) => {
  return (
    <div className="pt-33 pb-20 lg:pt-105 lg:pb-90">
      <Container>
        <div className="grid grid-cols-1 gap-22 lg:grid-cols-2 lg:grid-rows-2 lg:gap-85">
          <Title className="lg:row-start-1 lg:col-start-2" tag="h2" type="md">{title}</Title>
          <div className="relative lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-3">
            <ExportedImage
              src={AgencyImage}
              blurDataURL={AgencyImage.blurDataURL}
              placeholder="blur"
              alt={image.alt}
              className="w-full h-auto"
            />
          </div>
          <div className="lg:self-end lg:row-start-2 lg:col-start-2">
            <Subtitle tag="p">{subtitle}</Subtitle>
            <ButtonPrimary
              href={cta.href}
              icon={<ArrowButton />}
              className="mt-33 lg:mt-42">
              {cta.label}
            </ButtonPrimary>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Agency