import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import Container from '@components/ui/Container'
import Title from '@components/ui/Title'
import Skill from '@components/ui/Skill'
import { Category } from '../../types'

gsap.registerPlugin(ScrollTrigger)
interface SkillsProps {
  title: string,
  categories: Category[],
}

const Skills = ({ title, categories }: SkillsProps) => {
  const skillsContainer = useRef<HTMLDivElement>(null)
  const titleEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const showToaster = gsap.to('[data-toaster]', {
      duration: 0.5,
      autoAlpha: 1,
      display: 'block',
      y: 0,
    })

    ScrollTrigger.create({
      trigger: skillsContainer.current,
      start: 'top center',
      animation: showToaster,
      once: true,
    })

    ScrollTrigger.create({
      trigger: skillsContainer.current,
      start: 'top center',
      end: 'bottom center',
      scrub: true,
      animation: gsap.fromTo('[data-progress]', {
        width: 0,
      }, {
        width: '100%',
        stagger: 0.5,
      })
    })
  }, [])

  useEffect(() => {
    const titleWords = gsap.utils.toArray('[data-word]', titleEl.current)

    const tl = gsap.timeline({
      defaults: {
        duration: 0.5,
      },
      scrollTrigger: {
        trigger: titleEl.current,
        start: 'top 80%',
      }
    })

    tl
      .from(titleWords, {
        autoAlpha: 0,
        y: '100%',
        stagger: 0.05,
      })
  }, [])

  return (
    <div ref={skillsContainer} className="pt-25 pb-90">
      <Container>
        <Title ref={titleEl} tag="h2" type="lg">{ title }</Title>

        <div className="mt-40 lg:mt-100">
          {categories.map((category, index) => (
            <Skill
              key={index}
              category={category}
            />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Skills