import { useEffect, useRef } from 'react'
import gsap from 'gsap'

import Title from '@components/ui/Title'
import Container from '@components/ui/Container'
import Subtitle from '@components/ui/Subtitle'

interface ToplineProps {
  title: string
  subtitle: string
}

const Topline = ({ title, subtitle }: ToplineProps) => {
  const titleEl = useRef<HTMLHeadingElement>(null)
  const subtitleEl = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    const titleWords = gsap.utils.toArray('[data-word]', titleEl.current)
    const subtitleWords = gsap.utils.toArray('[data-word]', subtitleEl.current)

    const tl = gsap.timeline({
      defaults: {
        duration: 0.5,
      },
      scrollTrigger: {
        trigger: titleEl.current,
        start: 'top 80%',
      }
    })

    tl
      .from(titleWords, {
        autoAlpha: 0,
        y: '100%',
        stagger: 0.1,
      })
      .from(subtitleWords, {
        autoAlpha: 0,
        y: '100%',
        stagger: 0.05,
      }, 0.5)
      
  }, [])

  return (
    <section className="pt-50 pb-75 lg:py-160">
      <Container>
        <Title ref={titleEl} tag="h2" type="lg">{title}</Title>
        <Subtitle ref={subtitleEl} tag="h3" className="mt-22 lg:mt-40">{subtitle}</Subtitle>
      </Container>
    </section>
  )
}

export default Topline
