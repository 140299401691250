import { useRef, useEffect } from 'react'
import ExportedImage from 'next-image-export-optimizer'
import gsap from 'gsap'

import FoundersImage from '@public/images/founders.webp'

import Container from '@components/ui/Container'
import Subtitle from '@components/ui/Subtitle'
import Title from '@components/ui/Title'
import { Image, TwoLinesSubtitle } from '../../types'

interface FoundersProps {
  title: string,
  subtitle: TwoLinesSubtitle,
  image: Image,
}

const Founders = ({ title, subtitle, image }: FoundersProps) => {
  const titleEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const titleWords = gsap.utils.toArray('[data-word]', titleEl.current)

    const tl = gsap.timeline({
      defaults: {
        duration: 0.5,
      },
      scrollTrigger: {
        trigger: titleEl.current,
        start: 'top 80%',
      }
    })

    tl
      .from(titleWords, {
        autoAlpha: 0,
        y: '100%',
        stagger: 0.05,
      })
  }, [])


  return (
    <div className="pt-45 pb-16 lg:pt-125 lg:pb-85">
      <Container>
        <Title ref={titleEl} tag="h2" type="lg">{ title }</Title>
        <Subtitle tag="h3" className="mt-22 lg:mt-48">{ subtitle.line1 }</Subtitle>
        <Subtitle tag="h3">{ subtitle.line2 }</Subtitle>
        <div className="relative mt-40 aspect-founders lg:mt-60">
          <ExportedImage
            src={FoundersImage}
            blurDataURL={FoundersImage.blurDataURL}
            placeholder="blur"
            alt={image.alt}
            fill
          />
        </div>
      </Container>
    </div>
  )
}

export default Founders