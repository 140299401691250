import React, { useCallback } from 'react'

interface TitleProps {
  children: string,
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p',
  type: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  className?: string
}

const Title = React.forwardRef<HTMLHeadingElement, TitleProps>(({ tag, className, type, children }, ref) => {
  const Tag = tag
  let baseClass = ''

  const wrapWords = useCallback((text: string) => {
    return text.replace(
      // eslint-disable-next-line no-control-regex
      /([^\x00-\x80]|\S|\w,)+/g,
      `<span class="inline-block overflow-hidden leading-${type === 'md' ? 'snug' : 'tight' }">
        <span class="inline-block leading-none" data-word>$&</span>
      </span>`
    )
  }, [])

  switch (type) {
    case 'md':
      baseClass = 'text-40 leading-0 font-standard'
      break
    case 'lg':
      baseClass = 'text-70 leading-0 font-standard'
      break
    case 'xl':
      baseClass = 'text-105 leading-0'
      break
  }
  
  return (
    <Tag
      ref={ref}
      className={`${className} ${baseClass}`}
      dangerouslySetInnerHTML={{ __html: wrapWords(children) }}
    />
  )
})

export default Title