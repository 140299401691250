import Container from '@components/ui/Container'
import ImageFallback from '@components/ui/ImageFallback'

import { Brand } from '../../types'

interface BrandsProps {
  brands: Brand[]
}

const Brands = ({ brands }: BrandsProps) => {
  return (
    <div className="pb-107 lg:pb-258">
      <Container>
        <div className="grid grid-cols-2 gap-x-15 gap-y-50 place-items-center lg:grid-cols-4 lg:gap-x-85 lg:gap-y-150">
          {brands.map(({ name, image }, index) => (
            <div key={index}>
              <ImageFallback
                className="!max-w-1/2 mx-auto lg:!max-w-full"
                src={image.src}
                fallbackSrc={image.fallback}
                alt={name}
                width={image.width}
                height={image.height}
              />
              <span className="sr-only">{name}</span>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Brands