import { useCallback, useEffect, useState, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import useTranslation from '@hooks/useTranslation'
import Container from '@components/ui/Container'
import Title from '@components/ui/Title'
import { Award } from '../../types'

gsap.registerPlugin(ScrollTrigger)

interface AwardsProps {
  title: string,
  awards: Award[],
  max: number,
}

const Awards = ({ title, awards, max }: AwardsProps) => {
  const [toggle, setToggle] = useState(false)
  const [timeline] = useState<gsap.core.Timeline>(gsap.timeline({ paused: true }))
  const { t } = useTranslation()
  const titleEl = useRef<HTMLDivElement>(null)
  const containerEl = useRef<HTMLDivElement>(null)
  const hiddenAwardsEl = useRef<HTMLDivElement>(null)

  const visibleAwards = awards.slice(0, max)
  const hiddenAwards = awards.slice(max)

  const handleToggle = useCallback(() => {
    setToggle(!toggle)

    setTimeout(() => {
      ScrollTrigger.refresh()

      if (toggle && hiddenAwardsEl.current) {
        window.scrollBy({
          top: -hiddenAwardsEl.current?.clientHeight,
          behavior: 'auto',
        })
      }
    }, 0)
  }, [toggle])

  useEffect(() => {
    const awardsEls = gsap.utils.toArray('[data-awards-item]') as HTMLElement[]
    timeline
      .from(awardsEls, {
        duration: 0.5,
        autoAlpha: 0,
        y: 20,
        stagger: 0.1,
      })
  }, [])

  useEffect(() => {
    const titleWords = gsap.utils.toArray('[data-word]', titleEl.current) as HTMLElement[]
    const tl = gsap.timeline({
      defaults: {
        duration: 0.5,
      },
      scrollTrigger: {
        trigger: titleEl.current,
        start: 'top 80%',
      },
    })

    tl
      .from(titleWords, {
        autoAlpha: 0,
        y: '100%',
        stagger: 0.05,
      })
  }, [])

  useEffect(() => {
    if (toggle) {
      timeline.play()
    } else {
      timeline.reverse()
    }
  }, [toggle])
  
  return (
    <div ref={containerEl} className="pt-45 pb-110 lg:pt-125 lg:pb-290">
      <Container>
        <Title ref={titleEl} tag="h2" type="lg">{ title }</Title>

        <div className="mt-40 lg:mt-160 lg:grid lg:grid-cols-6 lg:gap-x-85">
          {visibleAwards.map((award, index) => (
            <div key={index} className="relative py-20 border-b border-white/25 lg:py-30 lg:flex lg:justify-between lg:gap-30 lg:items-center lg:col-start-2 lg:col-end-6">
              <div className="text-18 font-medium leading-loose lg:inline-flex lg:gap-10">
                <p className="max-w-1/2 uppercase">{award.title}</p>
                <p className="text-gray-medium">{award.subtitle}</p>
              </div>
              <p className="absolute top-20 right-0 text-18 font-medium lg:relative lg:top-0">{ award.client }</p>
            </div>
          ))}
        </div>

        <div ref={hiddenAwardsEl} className={`lg:grid lg:grid-cols-6 lg:gap-x-85 ${!toggle ? 'hidden lg:hidden' : ''}`}>
          {hiddenAwards.map((award, index) => (
            <div key={index} data-awards-item className="relative py-20 border-b border-white/25 lg:py-30 lg:flex lg:justify-between lg:gap-30 lg:items-center lg:col-start-2 lg:col-end-6">
              <div className="text-18 font-medium leading-loose lg:inline-flex lg:gap-10">
                <p className="max-w-1/2 uppercase">{award.title}</p>
                <p className="text-gray-medium">{award.subtitle}</p>
              </div>
              <p className="absolute top-20 right-0 text-18 font-medium lg:relative lg:top-0">{award.client}</p>
            </div>
          ))}
        </div>
        
        <div className="lg:grid lg:grid-cols-6 lg:gap-x-85">
          <div className="text-right lg:col-start-2 lg:col-span-4 lg:mt-32">
            <button
              onClick={handleToggle}
              className="inline-flex items-center gap-7 mt-22 text-18 text-gray-medium uppercase font-medium">
              <span>
                {/* @ts-ignore */}
                {toggle ? t('home.awards.toggle.less') : t('home.awards.toggle.more')}</span>
              <i className="relative block w-11 h-11">
                <span className="absolute top-1/2 left-0 w-full h-2 bg-gray-medium transform -translate-y-1/2"></span>
                <span className={`absolute top-1/2 left-0 w-full h-2 bg-gray-medium transition-transform transform -translate-y-1/2 ${!toggle && 'rotate-90'}`}></span>
              </i>
            </button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Awards