import { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import useTranslation from '@hooks/useTranslation'
import Container from '@components/ui/Container'
import Title from '@components/ui/Title'
import Topline from '@components/sections/Topline'
import Subtitle from '@components/ui/Subtitle'
import ImagesGalleryFallback from '@components/ui/ImagesGalleryFallback'
import ArrowMask from '@components/icons/ArrowMask'
import ImageFallback from '@components/ui/ImageFallback'

gsap.registerPlugin(ScrollTrigger)

interface HeroProps {
  title: string
  subtitle: string
  appIsReady: boolean
}

const Hero = ({ title, subtitle, appIsReady }: HeroProps) => {
  const videoContainerEl = useRef<HTMLDivElement>(null)
  const videoEl = useRef<HTMLDivElement>(null)
  const titleEl = useRef<HTMLDivElement>(null)
  const subtitleEl = useRef<HTMLDivElement>(null)
  const stampRef = useRef<HTMLDivElement>(null)
  const [isVideoReady, setIsVideoReady] = useState(false)
  const { t } = useTranslation()

  const rotateArrow = gsap.timeline({
    paused: true,
    defaults: {
      ease: 'linear',
    },
  })

  const entrance = gsap.timeline({
    duration: 0.5,
    delay: 0.5,
  })
  
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    if (isSafari) return

    rotateArrow
      .to(videoContainerEl.current, {
        rotate: -180,
      })
      .to(videoEl.current, {
        rotate: 180,
      }, 0)

    ScrollTrigger.create({
      id: 'gau_hero',
      trigger: videoContainerEl.current,
      animation: rotateArrow,
      toggleActions: 'play reverse play reverse',
      scrub: true,
      start: 'top-=500px top',
      end: 'bottom+=500px bottom',
    })
  }, [videoContainerEl, videoEl])

  useEffect(() => {
    if (!appIsReady) return

    const titleWords = gsap.utils.toArray('[data-word]', titleEl.current)
    const subtitleWords = gsap.utils.toArray('[data-word]', subtitleEl.current)

    entrance
      .from(videoContainerEl.current, {
        y: 100,
        autoAlpha: 0,
        onStart: () => {
          // @ts-ignore
          videoContainerEl.current.classList.remove('invisible')
          document.body.classList.remove('overflow-hidden')
        }
      })
      .from(titleWords, {
        y: '100%',
        stagger: 0.1,
        autoAlpha: 0,
        // @ts-ignore
        onStart: () => titleEl.current.classList.remove('invisible'),
      })
      .from(subtitleWords, {
        y: '100%',
        duration: 0.3,
        autoAlpha: 0,
        stagger: 0.05,
        // @ts-ignore
        onStart: () => subtitleEl.current.classList.remove('invisible')
      }, '<0.3')
      .from(stampRef.current, {
        y: 25,
        autoAlpha: 0,
        // @ts-ignore
        onStart: () => stampRef.current.classList.remove('invisible')
      }, '<0.3')

    
  }, [videoContainerEl, titleEl, subtitleEl, appIsReady])

  return (
    <section className="hero pb-50 pt-120 overflow-hidden">
      <div className="relative z-10">
        <Container>
          <div className="pb-50 lg:pb-130 md:grid md:grid-cols-6 lg:gap-85 md:items-end">
            
            <Title
              ref={titleEl}
              type="xl"
              tag="h2"
              className="invisible font-cycle md:col-start-1 md:col-end-4">
                {title}
              </Title>
            
            <div className="w-full mt-30 md:mt-0 md:col-start-5 md:col-end-7 md:self-end">
              <div ref={stampRef} className="invisible max-w-[180px] md:max-w-none">
                <ImageFallback
                  src="/images/stamp.webp"
                  alt="Élue agence de marketing mobile 2023"
                  fallbackSrc="/images/stamp.png"
                  width={220}
                  height={110}
                />
              </div>
              <Subtitle
                ref={subtitleEl}
                tag="h3"
                className="invisible mt-30">
                {subtitle}
              </Subtitle>
            </div>
          </div>
        </Container>
      </div>
      <div ref={videoContainerEl} className="invisible relative z-0 w-full aspect-square overflow-hidden -md:!rotate-0">
        <div className="absolute w-full h-full inset-0 z-10 text-primary">
          <ArrowMask />
        </div>
        <div ref={videoEl} className="relative aspect-square z-0 origin-center -md:!rotate-0">
          <video
            autoPlay
            loop
            muted
            playsInline
            onLoadedData={() => setIsVideoReady(true)}
            onError={() => setIsVideoReady(false)}
            src={t('home.hero.video') as string}
            className="hero-video max-w-none absolute z-10 -left-diagonal-offset -top-diagonal-offset w-diagonal h-diagonal"
          />

          {!isVideoReady &&
            <div className="absolute z-0 -left-diagonal-offset -top-diagonal-offset w-diagonal h-diagonal">
              <ImagesGalleryFallback />
            </div>
          }
        </div>
      </div>

      <div className="relative z-10">
        <Topline title={t('home.topline.title')} subtitle={t('home.topline.subtitle')} />
      </div>
    </section>
  )
}
          

export default Hero