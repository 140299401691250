import { useState, useEffect, RefObject } from 'react'

const useOnScreen = (
  ref: RefObject<HTMLElement>,
  once: boolean = false,
  rootMargin: string = "0px",
  threshold: number = 1
) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIntersecting(true)
          if (once) {
            setTimeout(() => {
              observer.disconnect()
            }, 1000)
          }
        }
      },
      {
        rootMargin,
        threshold,
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [])
  return isIntersecting
}

export default useOnScreen