import { useEffect, useRef, useState } from 'react'
import Lottie from 'react-lottie-player'

import useOnScreen from '@hooks/useOnScreen'

import Title from '@components/ui/Title'

interface SkillProps {
  category: {
    title: string,
    items: Array<string>,
    animation: string,
  }
}

const Skill = ({ category }: SkillProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(ref, true)
  const [animation, setAnimation] = useState<object | undefined | false>(false)

  useEffect(() => {
    if (!onScreen) return
    import(`../../lotties/${category.animation}`).then(setAnimation)
  }, [onScreen])

  return (
    <div ref={ref} className="relative py-25 border-t border-black/25 lg:py-35 lg:grid lg:grid-cols-2 lg:gap-85">
      <div data-progress className="absolute -top-1 left-0 h-1 bg-black" />
      <div className="flex items-center gap-20">
        <div className="w-40 h-40 lg:w-75 lg:h-75 lg:gap-30">
          { animation &&
            <Lottie
              // @ts-ignore
              animationData={animation}
              play
              loop={false}
            />
          }
        </div>
        <Title tag="h3" type="md" className="text-black/30">{category.title}</Title>
      </div>

      <ul className="mt-15 lg:mt-0">
        {category.items.map((item, index) => (
          <li key={index}>
            <Title tag="p" type="md">{item}</Title>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Skill