import { useRef } from 'react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'

import Logo from '@components/icons/Logo'

interface LoaderProps {
  appIsReady: boolean,
  visible: boolean,
}

const Loader = ({ appIsReady, visible }: LoaderProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const progressRef = useRef<HTMLDivElement>(null)
  const bgRef = useRef<HTMLDivElement>(null)

  useGSAP(() => {
    if (!visible) return

    const tl = gsap.timeline({
      defaults: {
        duration: 1,
      },
      onStart: () => document.body.classList.add('overflow-hidden'),
      onComplete: () => document.body.classList.remove('overflow-hidden'),
    })

    tl
      .from(containerRef.current, {
        y: 100,
        autoAlpha: 0,
      })
      .to(overlayRef.current, {
        y: '-100%',
      }, '<0.2')
      .to(progressRef.current, {
        scaleX: 1,
        duration: 1.5,
      }, '<0.75')
      .to(progressRef.current, {
        autoAlpha: 0,
        duration: 0.3,
      }, '+=0.5')
      .to(containerRef.current, {
        scale: 0.75,
        autoAlpha: 0,
        duration: 0.5,
      }, '+=1')
      .to(bgRef.current, {
        autoAlpha: 1,
        scale: 1,
        y: 0,
      }, '<')

    return () => {
      tl.kill()
      document.body.classList.remove('overflow-hidden')
    }
  }, [overlayRef, containerRef, progressRef, visible])
  
  return (
    <div className={`hidden absolute z-50 bg-black inset-0 flex-col items-center justify-center h-full ${(!appIsReady && visible) ? '!flex' : ''}`}>
      <div className="w-1/2 text-white">
        <div ref={containerRef} className="relative overflow-hidden">
          <div ref={overlayRef} className="absolute inset-0 bg-black z-10"></div>
          <Logo timeout={2400} />
          <div ref={progressRef} className="w-4/5 mt-20 h-1 bg-white/50 transform mx-auto scale-x-0"></div>
        </div>
      </div>

      <div ref={bgRef} className="invisible opacity-0 transform translate-y-full scale-50 absolute inset-0 bg-primary"></div>
    </div>
  )
}

export default Loader