import { useEffect, useRef, useContext, ReactNode } from 'react'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Head from 'next/head'

import OgImage from '@public/images/goandup_og.jpg'

import useTranslation from '@hooks/useTranslation'
import Header from '@components/sections/Header'
import Footer from '@components/sections/Footer'
import Loader from '@components/ui/Loader'
import { AppContext } from '@components/contexts/AppContext'

import { Theme } from '../../types'

type LayoutProps = {
  children: ReactNode
}

gsap.registerPlugin(ScrollTrigger)

export default function Layout({ children }: LayoutProps) {
  const { t } = useTranslation()
  const headerEl = useRef<HTMLDivElement>(null)

  ScrollTrigger.clearScrollMemory()

  const {
    theme,
    isMounted,
    appVisible,
    loaderVisible,
    headerHeight,
    loader,
    origin,
    setTheme,
    setIsMounted,
    setAppVisible,
    setLoaderVisible,
    setHeaderHeight,
  } = useContext(AppContext)

  useEffect(() => {
    setHeaderHeight(headerEl.current?.offsetHeight || 0)
  }, [headerEl])

  useGSAP(() => {
    if (!isMounted) return

    const sections = gsap.utils.toArray('[data-section]') as HTMLElement[]

    sections.forEach(section => {
      if (section.querySelector('[data-section-scale]')) {
        gsap.to(section.querySelector('[data-section-scale]'), {
          opacity: 0,
          scale: 0.9,
          y: 50,
          scrollTrigger: {
            trigger: section,
            scrub: true,
            start: 'bottom bottom',
            pin: true,
            pinSpacing: false,
            invalidateOnRefresh: true,
          }
        })
      }

      ScrollTrigger.create({
        trigger: section,
        start: `top top+=${headerHeight}`,
        end: `bottom top+=${headerHeight}`,
        onEnter: () => setTheme(section.dataset.section as Theme),
        onLeaveBack: () => setTheme(section.dataset.section as Theme),
        onEnterBack: () => setTheme(section.dataset.section as Theme),
        onLeave: () => setTheme(section.dataset.section as Theme),
      })
    })
  }, [isMounted])

  useEffect(() => {
    if (loader) {
      setIsMounted(true)
      setAppVisible(true)
      setLoaderVisible(false)
    } else if (loader === null) {
      setLoaderVisible(true)
      setTimeout(() => {
        setIsMounted(true)
        setAppVisible(true)
        setLoaderVisible(false)
        sessionStorage.setItem('loader', 'true')
      }, 5150)
    }

  }, [loader, isMounted])

  return (
    <>
      <Head>
        <title>{t('seo.title')}</title>
        <meta name="description" content={t('seo.description') as string} />
        <meta property="og:title" content={t('seo.title') as string} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={t('seo.url') as string} />
        <meta property="og:site_name" content={t('seo.site_name') as string} />
        <meta property="og:description" content={t('seo.description') as string} />
        <meta property="og:email" content={t('seo.email') as string} />
        <meta property="og:street-address" content={t('seo.address.street') as string} />
        <meta property="og:locality" content={t('seo.address.city') as string} />
        <meta property="og:postal-code" content={t('seo.address.zip') as string} />
        <meta property="og:country-name" content={t('seo.address.country') as string} />
        <meta property="og:image" content={origin + OgImage.src} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={origin + OgImage.src} />

        <link
          rel="canonical"
          href={t('seo.url') as string}
          key="canonical"
        />
      </Head>

      <Loader appIsReady={isMounted} visible={loaderVisible} />

      <div className={`overflow-x-hidden ${appVisible ? 'opacity-100' : 'opacity-0'}`}>
        <main className="relative z-10">
          <Header ref={headerEl} theme={theme} appIsReady={isMounted} />

          {children}

          <Footer />
        </main>
      </div>
    </>
  )
}