import Container from '@components/ui/Container'
import Subtitle from '@components/ui/Subtitle'
import ButtonWTTJ from '@components/ui/ButtonWTTJ'

import WTTJIcon from '@components/icons/WTTJIcon'

interface WTTJProps {
  title: string,
  cta: {
    label: string,
    href: string,
  },
}

const WTTJ = ({ title, cta }: WTTJProps) => {
  return (
    <div className="pt-16 pb-25 lg:pt-85 lg:pb-105">
      <Container>
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-85">
          <div className="lg:col-start-2">
            <Subtitle tag="p">{title}</Subtitle>
            <ButtonWTTJ
              href={cta.href}
              target="_blank"
              icon={<WTTJIcon />}
              className="mt-30 lg:mt-40"
            >
              {cta.label}
            </ButtonWTTJ>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default WTTJ