import { useCallback, useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import Container from '@components/ui/Container'
import Title from '@components/ui/Title'
import ImageFallback from '@components/ui/ImageFallback'
import { Image } from '../../types'

gsap.registerPlugin(ScrollTrigger)

interface TeamProps {
  title: string,
  images: Image[][],
}

const Team = ({ title, images }: TeamProps) => {
  const teamContainer = useRef<HTMLDivElement>(null)

  const onScrollAnimation = useCallback(() => {
    ScrollTrigger.create({
      trigger: teamContainer.current,
      start: 'top center',
      end: 'bottom center',
      scrub: true,
      animation: gsap.fromTo('[data-team-row]', {
        x: (i: number) => (i % 2 === 0 ? '5%' : '-5%'),
      }, {
        x: (i: number) => (i % 2 === 0 ? '-5%' : '5%'),
      })
    })
  }, [])
  
  const makeItRain = useCallback(() => {
    const timeline = gsap.timeline({
      paused: true,
      defaults: {
        duration: 0.5,
      },
    })

    timeline
      .from('[data-team-image]', {
        autoAlpha: 0,
        y: (i, target) => -target.clientHeight / 2,
        stagger: 0.01,
      })
      .from('[data-team-title]', {
        autoAlpha: 0,
      }, 1)

    ScrollTrigger.create({
      trigger: teamContainer.current,
      start: 'top 80%',
      animation: timeline
    })
  }, [])

  useEffect(() => {
    makeItRain()
    onScrollAnimation()
  }, [])

  return (
    <div ref={teamContainer} className="pt-25 pb-100 lg:pt-105 lg:pb-225 overflow-hidden">
      <div className="relative">
        <div className="flex flex-col items-center">
          {images.map((row, index) => (
            <div key={index} data-team-row className={`grid grid-flow-col gap-40 mb-40 last:mb-0 lg:gap-85 lg:mb-85`}>
              {row.map((image, index) => (
                <div data-team-image key={index} className="relative aspect-members min-w-team lg:min-w-team-lg">
                  <ImageFallback
                    src={image.src}
                    alt={image.alt}
                    fallbackSrc={image.fallback}
                    width={417}
                    height={295}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="absolute top-1/2 inset-x-0 transform -translate-y-1/2">
          <Container>
            <div data-team-title className="text-center">
              <Title tag="h2" type="lg">{title}</Title>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Team