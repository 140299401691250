import React from 'react'

import useTranslation from '@hooks/useTranslation'
import ArrowButton from '@components/icons/ArrowButton'
import Linkedin from '@components/icons/Linkedin'
import Facebook from '@components/icons/Facebook'
import Twitter from '@components/icons/Twitter'
import Logo from '@components/icons/Logo'
import Container from '@components/ui/Container'
import Title from '@components/ui/Title'

import { Network, SocialIcons } from '../../types'

const Footer = () => {
  const { t, o } = useTranslation()
  const networks = o('networks') as Network[]

  const socialIcons = {
    linkedin: <Linkedin />,
    facebook: <Facebook />,
    twitter: <Twitter />,
  } as SocialIcons

  return (
    <footer className="relative z-20 pt-45 pb-65 bg-black text-white rounded-t-15 -translate-y-15 lg:pt-125 lg:pb-100">
      <Container>
        <div className="lg:grid lg:grid-cols-6 lg:gap-85">
          <div className="lg:col-start-1 lg:col-span-4">
            <Title tag="h2" type="lg">{t('footer.title')}</Title>
            <a href={`mailto:${t('footer.email.contact')}`} className="group mt-20 inline-flex items-center lg:mt-50">
              <i className="w-24 h-24 basis-24 mr-10 transform transition-transform -rotate-90 group-hover:rotate-0">
                <ArrowButton />
              </i>
              <span className="text-18 font-bold uppercase">{t('footer.email.contact')}</span>
            </a>
          </div>

          <div className="mt-48 lg:mt-0 lg:pl-80 lg:col-start-5 lg:col-span-2 lg:border-l lg:border-white/25">
            <p className="text-70">{t('footer.address.city')}</p>
            <ul className="text-18 font-medium leading-relaxed lg:mt-20">
              <li>
                  <a
                    className="hover:underline"
                    href={t('footer.address.gmap') as string}
                    target="_blank"
                    rel="noreferrer">
                    {t('footer.address.street')}<br/>
                    {t('footer.address.zip')}
                  </a>
                </li>
              <li>
                <a className="hover:underline" href={t('footer.phone.link') as string}>{t('footer.phone.label')}</a>
              </li>
            </ul>

            <a href={`mailto:${t('footer.email.jobs')}`} className="group mt-20 inline-flex items-center lg:mt-30">
              <i className="w-24 h-24 basis-24 mr-10 transform transition-transform -rotate-90 group-hover:rotate-0">
                <ArrowButton />
              </i>
              <span className="text-18 font-bold uppercase">{t('footer.email.jobs')}</span>
            </a>
          </div>
        </div>

        <div className="my-60 lg:my-110">
          <Logo />
        </div>

        <div className="lg:flex lg:justify-between lg:gap-30">
          <p className="text-18">{t('footer.copy')}</p>
          <ul className="mt-25 flex gap-30">
            {networks.map(({ href, label, icon }) => (
              <li key={label}>
                <a href={href} className="text-18 font-medium text-white" target="_blank" rel="noreferrer">
                  {socialIcons[icon]}
                  <span className="sr-only">{label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default Footer