import { useRef, useEffect } from 'react'
import gsap from 'gsap'

import Title from '@components/ui/Title'
import Project from '@components/ui/Project'

import { TProject } from '../../types'

interface ProjectProps {
  title: string,
  projects: TProject[]
}

const Projects = ({ title, projects }: ProjectProps) => {
  const titleEl = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    const titleWords = gsap.utils.toArray('[data-word]', titleEl.current)

    const tl = gsap.timeline({
      defaults: {
        duration: 0.5,
      },
      scrollTrigger: {
        trigger: titleEl.current,
        start: 'top 80%',
      }
    })

    tl
      .from(titleWords, {
        autoAlpha: 0,
        y: '100%',
        stagger: 0.05,
      })
  }, [])

  return (
    <div className="pt-60 pb-35 text-white lg:pt-200 lg:pb-110">
      <div className="grid-overflow-container">
        <Title ref={titleEl} tag="h3" type="lg">{title}</Title>
        <div className="grid-overflow pt-40 scrollbar-hide lg:pt-75">
          <div className="grid-overflow-inner lg:grid lg:gap-85 lg:grid-cols-3">
            {projects.map((project, index) => (
              <Project key={index} {...project} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects