import Container from '@components/ui/Container'
import { useCallback, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import { Keyfigure } from '../../types'

gsap.registerPlugin(ScrollTrigger)


interface KeyfiguresProps {
  keyfigures: Keyfigure[],
}

const Keyfigures = ({ keyfigures }: KeyfiguresProps) => {
  const containerEl = useRef(null)

  const countUp = useCallback(() => {
    if (!containerEl.current) return

    const numbers = gsap.utils.toArray('[data-number]', containerEl.current) as HTMLElement[]
    numbers.forEach((number: HTMLElement) => {
      const numberEl = number
      const numberFinal = Number(numberEl.dataset.number) || 0

      gsap.to(numberEl, {
        duration: 2,
        ease: 'power4.out',
        innerHTML: numberFinal,
        roundProps: 'innerHTML',
        onUpdate: () => {
          const numberCurrent = parseInt(numberEl.innerHTML)
          if (numberCurrent >= numberFinal) {
            numberEl.innerHTML = numberFinal.toString()
          }
        },
      })
    })
    
  }, [])

  useEffect(() => {
    ScrollTrigger.create({
      id: 'gau_keyfigures',
      trigger: containerEl.current,
      onEnter: () => countUp(),
      toggleActions: 'play none none none',
      start: 'top center',
      end: 'bottom bottom',
      // refreshPriority: 0,
    })
  }, [containerEl])

  return (
    <div ref={containerEl} className="pt-60 pb-35 text-white lg:pt-200 lg:pb-110">
      <Container>
        <div className="grid grid-cols-2 gap-x-15 gap-y-40 place-items-center lg:grid-cols-3 lg:gap-x-85 lg:gap-y-110">
          {keyfigures.map((keyfigure, index) => (
            <div key={index} className="flex flex-col items-center">
              <p className="text-105 leading-none">
                {keyfigure.prefix}
                <span data-number={keyfigure.number.final} data-number-increment={keyfigure.number.increment}>{keyfigure.number.inital}</span>
                {keyfigure.suffix}
              </p>
              <p className="text-18 leading-relaxed uppercase font-medium">{keyfigure.label}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Keyfigures