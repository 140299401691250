/* eslint-disable */
import { createContext } from 'react'

import { Theme } from '../../types'

export const AppContext = createContext({
  theme: 'primary' as Theme,
  headerHeight: 0,
  loader: null as string | null,
  isMounted: false,
  appVisible: false,
  loaderVisible: false,
  origin: '',
  setTheme: (theme: Theme) => {},
  setHeaderHeight: (height: number) => {},
  setIsMounted: (mounted: boolean) => {},
  setAppVisible: (visible: boolean) => {},
  setLoaderVisible: (visible: boolean) => {},
})